import React, { useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactApexChart from "react-apexcharts";
import DatePicker from "react-datepicker"
import Select from 'react-select'

import Pagination from 'pages/parts/Pagination'

import { PGET, GET } from 'helpers/api'
import { formatMoney, formatDateBackend, formatDateWithTime } from 'helpers/helpers'

function Detail() {
	const { t } = useTranslation()
	const { id } = useParams();

	const inialGraphData = {
		series: [{
			name: 'Сумма покупки',
			data: []
		}],
		options: {
			//colors: ['#C5D1FF', '#00E396', '#eeeeee'],
			chart: {
				height: 350,
				type: 'area',
				toolbar: {
					show: false
				},
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				curve: 'smooth'
			},
			yaxis: {
				labels: {
					formatter: function (value) {
						return formatMoney(value, 0);
					}
				},
			},
			xaxis: {
				type: 'datetime',
				labels: {
					format: 'dd.MM',
				},
				tooltip: {
					enabled: false
				},
				categories: []
			},
			tooltip: {
				x: {
					format: 'dd.MM.yyyy'
				},
			},
		},
	}

	const [pageCount, setPageCount] = useState(0)
	const [poses, setPoses] = useState([])
	const [cheques, setCheques] = useState([])
	const [graphData, setGraphData] = useState({ ...inialGraphData })
	const [graphTotal, setGraphTotal] = useState({
		'amountPurchases': 0,
		'amountBonuses': 0,
		'amountWriteOff': 0,
		'avgCheque': 0
	})
	const [filterData, setFilterData] = useState({
		// 'from': formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		// 'to': formatDateBackend(new Date(date.getFullYear(), date.getMonth(), date.getDate())),
		'posId': null
	})
	const [loader, setLoader] = useState(true)

	async function getData() {
		setLoader(true)
		setGraphData({ ...inialGraphData })
		const response = await GET('/services/gocashweb/api/clients-main-report/' + id, filterData)
		if (response) {
			var graphDataCopy = { ...graphData }
			for (let i = 0; i < response['amountByDateList']?.length; i++) {
				graphDataCopy.series[0]['data'].push(response['amountByDateList'][i]['purchases'])

				graphDataCopy.options.xaxis.categories.push(response['amountByDateList'][i]['chequeDate'])
			}
			setGraphData(graphDataCopy)
			setGraphTotal({
				...graphTotal,
				'amountPurchases': response.amountPurchases,
				'amountBonuses': response.amountBonuses,
				'amountWriteOff': response.amountWriteOff,
				'avgCheque': response.avgCheque
			})

			const response2 = await PGET('/services/gocashweb/api/clients-cheque-pageList/' + id, filterData, { loader: true })
			if (response2) {
				setPageCount(Math.ceil(response2.headers['x-total-count'] / 20))
				setCheques(response2.data);
			}
		}
		setLoader(false)
	}

	async function paginate(data) {
		const response = await PGET('/services/gocashweb/api/clients-cheque-pageList/' + id, { ...filterData, page: data.selected, size: 20 }, { loader: true })
		if (response) {
			setCheques(response.data);
		}
	}

	async function getPoses() {
		const response = await GET('/services/gocashweb/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		getData()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps


	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('STATISTICS')}
				</h4>
				<div className="d-flex flex-wrap justify-content-end gap-2">
					<div className="w-25">
						<Select
							options={poses}
							value={poses.find(option => option.id === filterData.posId) || ''}
							onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
							placeholder=""
							noOptionsMessage={() => t('LIST_IS_EMPTY')}
							getOptionLabel={(option) => option.name}
							getOptionValue={(option) => option.id}
						/>
					</div>
					<div className="w-25">
						<div className="calendar-input">
							<DatePicker
								className="form-control"
								dateFormat="dd.MM.yyyy"
								selected={filterData.from ? new Date(filterData.from) : ''}
								onChange={(date) => setFilterData({ ...filterData, 'from': formatDateBackend(date) })} />
							<i className="uil uil-calendar-alt"></i>
						</div>
					</div>
					<div className="w-25">
						<div className="calendar-input">
							<DatePicker
								className="form-control"
								dateFormat="dd.MM.yyyy"
								selected={filterData.to ? new Date(filterData.to) : ''}
								onChange={(date) => setFilterData({ ...filterData, 'to': formatDateBackend(date) })} />
							<i className="uil uil-calendar-alt"></i>
						</div>
					</div>
					<button className="btn btn-primary" onClick={() => getData()}>
						{t('FILTER')}
					</button>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<div className="d-flex">
						<div className="d-flex flex-column justify-content-between me-5">
							<strong className="fz14">{t('PURCHASE_AMOUNT')}</strong>
							<div className="position-relative">
								<span className="fz24 text-success">{formatMoney(graphTotal.amountPurchases)}</span>
							</div>
						</div>
						<div className="d-flex flex-column justify-content-between me-5">
							<strong className="fz14">{t('AMOUNT_OF_BONUSES_ACCRUED')}</strong>
							<div className="position-relative">
								<span className="fz24 text-warning">{formatMoney(graphTotal.amountBonuses)}</span>
							</div>
						</div>
						<div className="d-flex flex-column justify-content-between me-5">
							<strong className="fz14">{t('BONUSES_WRITTEN_OFF')}</strong>
							<div className="position-relative">
								<span className="fz24 text-info">{formatMoney(graphTotal.amountWriteOff)}</span>
							</div>
						</div>
						<div className="d-flex flex-column justify-content-between">
							<strong className="fz14">{t('AVERAGE_CHECK')}</strong>
							<div className="position-relative">
								<span className="fz24 text-dark">{formatMoney(graphTotal.avgCheque)}</span>
							</div>
						</div>
					</div>
					<hr />
					{!loader &&
						<ReactApexChart options={graphData.options} series={graphData.series} type="area" height={300} />
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-hover mb-0">
							<thead>
								<tr>
									<th>{t('DATE')}</th>
									<th className="text-center">{t('CHECK_AMOUNT')}</th>
									<th className="text-center">{t('PAID_IN_SUMS')}</th>
									<th className="text-center">{t('PAID_WITH_BALLS')}</th>
									<th className="text-center">{t('BONUS')}</th>
									<th className="text-center">{t('CASHIER')}</th>
									<th className="text-center">{t('POS')}</th>
									<th className="text-center">{t("STATUS")}</th>
								</tr>
							</thead>
							<tbody>
								{cheques.map((item, index) => (
									<tr key={index}>
										<td>{formatDateWithTime(item.chequeDate)}</td>
										<td className="text-center">{formatMoney(item.totalAmount)}</td>
										<td className="text-center">{formatMoney(item.cashPayment)}</td>
										<td className="text-center">{formatMoney(item.writeOff)}</td>
										<td className="text-center">{formatMoney(item.bonus)}</td>
										<td className="text-center">{item.cashierName}</td>
										<td className="text-center">{item.posName}</td>
										<td className="text-center">
											{item.status === 1 &&
												<span className="text-primary">{t('CONFIRMED')}</span>
											}
											{item.status === 2 &&
												<span className="text-danger">{t('DENIED')}</span>
											}
											{item.status === 3 &&
												<span className="text-danger">{t('PROCESSING_ERROR')}</span>
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Detail