import React, { useEffect, useRef, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { Dropdown } from 'react-bootstrap'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import Pagination from 'pages/parts/Pagination'

import { FILE, GET, PGET } from 'helpers/api'
import { findFromArrayById, returnSign } from "helpers/helpers"
import { DebounceInput } from "react-debounce-input"

function Index() {
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)
	const reduxTableFilter = useSelector(state => state.tableFilter)

	const fileRef = useRef(null)

	const [categories, setCategories] = useState([])
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		'search': '',
		'categoryId': '',
		'complete': '',
		'page': '',
	})

	async function searchWithFilter(settings = { tableSort: '' }) {

		var filterUrl = "/services/gocashweb/api/product-pageList"
		var urlParams = ""
		checkFilter()

		if (filterData?.categoryId)
			urlParams += returnSign(urlParams) + 'categoryId=' + filterData?.categoryId
		if (filterData?.seasonal)
			urlParams += returnSign(urlParams) + 'seasonal=' + filterData?.seasonal
		if (filterData?.complete)
			urlParams += returnSign(urlParams) + 'complete=' + filterData?.complete
		if (filterData?.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData?.search
		if (filterData?.page)
			urlParams += returnSign(urlParams) + 'page=' + filterData?.page
		if (`/services/gocashweb/api/product-pageList${urlParams}` !== filterUrl)
			setFilterDataExist(true)

		if (settings.tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === settings.tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = settings.tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + settings.tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		const response = await PGET(`/services/gocashweb/api/product-pageList${urlParams}`, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
		}

		setDropdownIsOpen(false)
	}

	async function uploadExcel(e) {
		if (e.target?.files[0]) {
			let formData = new FormData();
			formData.append("file", e.target?.files[0]);
			const response = await FILE('/services/gocashweb/api/product-import', formData)
			if (response) {
				searchWithFilter(false)
			}
		}
	}

	function checkFilter() {
		if (
			!filterData.categoryId &&
			!filterData.complete &&
			!filterData.page &&
			!filterData.search
		) {
			setFilterDataExist(false)
		}
	}

	function resetFilter() {
		setFilterData({
			categoryId: null,
			complete: null,
			search: '',
		})
		setFilterDataExist(false)
		searchWithFilter()
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function paginate(data) {
		setFilterData({ ...filterData, 'page': data.selected })
	}

	async function getCategories() {
		const response = await GET('/services/gocashweb/api/product-category-helper')
		setCategories(response)
	}

	useEffect(() => {
		getCategories()
		searchWithFilter(false)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.page, filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex align-items-center">
					<h4 className="vertical-center me-2">
						{t('PRODUCTS')}
					</h4>
					<div>
						<button className="btn btn-outline-primary btn-wide" onClick={() => fileRef.current.click()}>{t('UPLOAD_EXCEL')}</button>
						<input type="file" style={{ visibility: 'hidden' }} ref={fileRef}
							onChangeCapture={uploadExcel}
							accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						/>
					</div>
				</div>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/products-create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<div className="position-relative me-2">
								<i className="uil-search input-search-icon"></i>
								<DebounceInput
									type="text"
									className="form-control"
									placeholder={t('SEARCH') + '...'}
									minLength={3}
									debounceTimeout={1000}
									value={filterData.search}
									onChange={(e) => {
										if (e.target.value.length > 0) {
											setFilterData({ ...filterData, search: e.target.value })
										} else {
											setFilterData({ ...filterData, search: '' })
										}
									}}
									onKeyUp={(e) => {
										if (e.keyCode === 13) {
											if (e.target.value.length > 0) {
												setFilterData({ ...filterData, search: e.target.value })
											} else {
												setFilterData({ ...filterData, search: '' })
											}

										}
									}}
								/>
							</div>
						</div>
						<div>
							<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('CATEGORY')}</label>
											<Select
												isClearable
												classNamePrefix="react-select"
												options={categories}
												value={categories.find(option => option.id === filterData.categoryId || '')}
												onChange={(option) => setFilterData({ ...filterData, 'categoryId': option?.id ? option.id : '' })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.categoryId &&
									<span className="me-2">
										<b>{t('CATEGORY')}: </b> {findFromArrayById(categories, filterData.categoryId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>ID</th>
									<th>{t('IMAGE2')}</th>
									<th className="th-sortable cursor" onClick={() => searchWithFilter({ tableSort: 'name' })}>
										{t('NAME_OF_PRODUCT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'name') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'name') && 'sort-active')}></i>
										</div>
									</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center">{t('CATEGORY')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className="tr-middle" key={index}>
										<td>{item.id}</td>
										<td>
											{item.imageUrl ?
												<img src={process.env.REACT_APP_PROD_SERVER_URL + item.imageUrl} alt="react" width="50" height="50" />
												:
												<div className="table-no-image">
													<i className="uil uil-camera position-relative fz-20" style={{ 'top': '20%' }}></i>
												</div>
											}
										</td>
										<td>{item.productName}</td>
										<td className="text-center">{item.barcode}</td>
										<td className="text-center">{item.categoryName}</td>
										<td>
											<div className="d-flex justify-content-center">
												<Link to={'/products-update/' + item.id}>
													<div className="table-action-button table-action-primary-button">
														<i className="uil-edit-alt"></i>
													</div>
												</Link>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate} filters={reduxTableFilter?.filters}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
